<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('bindAccount.default[0]')"
            left-arrow
            @click-left="$router.go(-2)"
    />
    <div class="ScrollBox">
          <van-form @submit="onSubmit">
            <div class="box">
                <van-field
                        v-model="BankInfo.bank_name"
                        :label="$t('bindAccount.fields2[3]')"
                        :placeholder="$t('bindAccount.placeholder2[3]')"
                />
                <van-divider />
                <van-field
                        v-model="BankInfo.name"
                        :label="$t('bindAccount.fields[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                />
                <van-divider />
                <van-field
                        v-model="BankInfo.card_no"
                        :label="$t('bindAccount.fields[2]')"
                        :placeholder="$t('bindAccount.placeholder[2]')"
                />
                <van-divider />
                <van-field
                        v-model="BankInfo.phone"
                        :label="$t('bindAccount.fields[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                />
                <van-divider />
            </div>
              <div style="line-height: 45px;color: #cccccc;text-align: left">
                  <div style="margin-left: 20px">{{$t('bindAccount2[0]')}}</div>
              </div>
            <div style="text-align: center;margin-top: 20px" v-show="canEdit">
              <p class="btn" @click="onSubmit">{{ $t('bindAccount2[1]') }}</p>
            </div>
              <div style="line-height: 45px;color: #cccccc">
                  <div style="margin-left: 20px">{{$t('busAuth2[4]')}}</div>
              </div>
              <div style="margin: 0px auto;width: 60%;text-align: center;margin-top: 30px">
                  <van-button color="#F0B80D" size="large" style="" round
                              to="/serviceCenter"
                  ><span style="color:#FFF">{{$t('bindAccount2[2]')}}</span>
                  </van-button>
              </div>
          </van-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      columns : ['ERC20', 'TRC20', 'OMNI'],
      BankInfo:{},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      canEdit:true,
      tabsList: [],
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()

    this.BankInfo.coin_type = this.columns[0];
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
        this.BankInfo = data['data'][0];
        this.canEdit = false;
      }
      }
    );
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    onSubmit() {
      this.$Model.AddBankCard(this.BankInfo,data=>{
         var msg = "";
         if (data.code==1){
             msg = this.$t('common4[1]');
             this.$router.push("/");
         }else{
             msg = data.code_dec;
         }
          this.$Dialog.Toast(msg);
      });
    },
    onChangeType(obj,value){
      this.BankInfo.coin_type = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
  .PageBox {
    color: #cccccc;
    background-color: #13171A;
  }
  .PageBox .box {
    width: 95%;
    padding: 10px 0px 10px 10px;
    font-size: 13px;
    margin-top: 15px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: #191C23;
    color: #cccccc;
  }
  .van-nav-bar {
      background-color: #191C23;
  }

  .van-divider {
      margin: 0px;
  }
  .van-nav-bar>>>.van-nav-bar__title{
      color:#cccccc;
  }

  .PageBox .van-cell{
      background-color: #191C23;
  }

.van-cell>>>.van-cell__title{
  font-size: 16px;
    width: 130px;
    color:#cccccc;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}

.van-field{
  font-weight: bold;
}

.btn {
    width: 85%;
    padding: 10px 50px;
    border-radius: 20px;
    background-color: #F0B80D;
    color: #FFF;
    font-size: 16px;
    text-align: center;
    margin: 15px 30px 30px;
}
</style>
